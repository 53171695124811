import { useQuery, gql, TypedDocumentNode } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { hrefFor } from '../../routers';
import { CustomPageQuery, CustomPageQueryVariables } from '../../generated/types';
import { findSectionModules } from '../../lib/findSectionModules';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { useCustomPageVariables } from '../../hooks/useCustomPageVariables';
import { Loading } from '../../components/Loading';
import { LazyModuleSection } from '../../components/LazyModuleSection';
import { PUMO_MODULE_FRAGMENT } from '../../components/PumoModule';
import { useShowEmailVerificationStatus } from '../../components/EmailVerificationStatus';
import { NotFoundPage } from '../NotFoundPage';
import { tc } from '../../lib/trackingContext';
import { useProcessModules } from './useProcessModules';

export const CUSTOM_PAGE_QUERY: TypedDocumentNode<CustomPageQuery, CustomPageQueryVariables> = gql`
  query CustomPageQuery(
    $slug: String!
    $countryCode: String
    $variation: Boolean
    $versionId: Int
    $ruleSuffix: String
  ) {
    displayPage(
      slug: $slug
      country_code: $countryCode
      variation: $variation
      version_id: $versionId
      rule_suffix: $ruleSuffix
    ) {
      name
      targetBuckets {
        name
        modules {
          ...PumoModuleFragment
        }
      }
      meta_description
    }
  }
  ${PUMO_MODULE_FRAGMENT}
`;

interface Props {
  variation?: boolean;
}

export const HomePage: React.FC<Props> = ({ variation = false }) => {
  const customPageVariables = useCustomPageVariables();
  const { vertical } = useParams<{ vertical?: string }>();
  const ruleAbTest = useFeatureFlag(`${customPageVariables.slug}_test`, [
    { default: false },
    { if: true, then: true }
  ]);

  // NOTICE: The variation variable is passed in from a feature flag and used to query SearchAPI for the variation version
  const { data, loading, error } = useQuery(CUSTOM_PAGE_QUERY, {
    variables: {
      ...customPageVariables,
      variation: variation,
      ruleSuffix: ruleAbTest ? 'test' : 'live'
    }
  });

  const topModules = useMemo(() => findSectionModules(data, 'top'), [data]);
  const headerModules = useMemo(() => findSectionModules(data, 'header'), [data]);
  const bottomModules = useMemo(() => findSectionModules(data, 'bottom'), [data]);

  const modules = useProcessModules(
    useMemo(
      () => [
        ...(topModules?.modules || []),
        ...(headerModules?.modules || []),
        ...(bottomModules?.modules || [])
      ],
      [topModules, headerModules, bottomModules]
    )
  );

  useShowEmailVerificationStatus();

  useEffect(() => {
    tc.setCurrent('display_page_name', data?.displayPage?.name);
    return () => {
      tc.setCurrent('display_page_name', undefined);
    };
  });

  if (error) throwQueryError(error);

  if (vertical === 'men') {
    return <Redirect to={hrefFor.HomePage()} />;
  }

  if (data && !data.displayPage) return <NotFoundPage />;

  if (loading) return <Loading />;

  return <LazyModuleSection modules={modules} />;
};
