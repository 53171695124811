import React from 'react';
import { AspectRatioBox } from '@moda/om';
import './PlaceholderModule.scss';

export const PlaceholderModule: React.FC = () => (
  <AspectRatioBox
    className="PlaceholderModule"
    maxWidth={4096}
    maxHeight={1560}
    aspectWidth={1366}
    aspectHeight={520}
  >
    <div className="PlaceholderModule__content" />
  </AspectRatioBox>
);
