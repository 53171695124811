import { colors } from '@moda/tokens';
import { PumoModuleFragment, SinglePumoModuleFragment } from '../../generated/types';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';

const isBannerModule = (mod: PumoModuleFragment): mod is SinglePumoModuleFragment =>
  mod.__typename === 'SinglePumoModule' && mod.name?.trim().toLowerCase() === 'banner';

const applyClubModaStylesToBanner = (mod: SinglePumoModuleFragment): SinglePumoModuleFragment => ({
  ...mod,
  bgColor: colors.all.ivory,
  slides: mod.slides.map(slide => ({
    ...slide,
    slideText: slide.slideText && {
      ...slide.slideText,
      textColor: colors.all['persian-blue']
    }
  }))
});

/**
 * Makes the banner have the Club Moda styling when the feature is enabled.
 */
export const useSwitchClubModaModules = (modules: PumoModuleFragment[]) => {
  const enableClubModaTreatment = useEnableClubModaTreatment();

  return modules.map(mod =>
    enableClubModaTreatment && isBannerModule(mod) ? applyClubModaStylesToBanner(mod) : mod
  );
};
