import { useCountry } from '../components/CountrySelector';
import { useUrlParams } from './useUrlParams';

export const useCustomPageVariables = ({ slug: defaultSlug = 'women' }: { slug?: string } = {}) => {
  const {
    params: { version_id: versionId, vertical, slug }
  } = useUrlParams();

  const parsedVersionId = versionId
    ? Array.isArray(versionId)
      ? Number(versionId[0])
      : Number(versionId as string)
    : false;

  const parsedSlug = Array.isArray(slug) ? slug[0] : slug;
  const parsedVertical = Array.isArray(vertical) ? vertical[0] : vertical;

  return {
    slug: parsedSlug || parsedVertical || defaultSlug,
    countryCode: useCountry().country.alpha2Code,
    ...(parsedVersionId && { versionId: parsedVersionId })
  };
};
