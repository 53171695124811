import React from 'react';
import { useBreakpoint } from '@moda/om';
import { PlaceholderModule } from '../PlaceholderModule';
import { LazyLoad, EnableLazyLoad } from '../LazyLoad';
import { ModuleSection } from '../ModuleSection';
import { PumoModuleFragment } from '../../generated/types';

type LazyModuleSectionProps = {
  modules: PumoModuleFragment[];
};

const INITIAL_MOBILE_MODULE_COUNT = 4;
const INITIAL_DESKTOP_MODULE_COUNT = 8;

export const LazyModuleSection: React.FC<LazyModuleSectionProps> = ({ modules }) => {
  const { matches: isMobile } = useBreakpoint({ lt: 'xs' });
  const initialModulesCount = isMobile ? INITIAL_MOBILE_MODULE_COUNT : INITIAL_DESKTOP_MODULE_COUNT;

  const initialModules = modules.slice(0, initialModulesCount);
  const lazyModules = modules.slice(initialModulesCount);

  return (
    <>
      <ModuleSection modules={initialModules} />
      <EnableLazyLoad>
        <LazyLoad fallback={<PlaceholderModule />}>
          <ModuleSection modules={lazyModules} />
        </LazyLoad>
      </EnableLazyLoad>
    </>
  );
};
