import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { StatusCodes } from 'http-status-codes';
import { Constrain, Stack } from '@moda/om';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { useStatusCode } from '../../hooks/useStatusCode';
import { Loading } from '../../components/Loading';
import { PageMeta } from '../../components/PageMeta';
import { ModuleSection } from '../../components/ModuleSection';
import { CUSTOM_PAGE_QUERY } from '../HomePage';
import { LazyLoad, EnableLazyLoad } from '../../components/LazyLoad';
import { findSectionModules } from '../../lib/findSectionModules';

import './NotFoundPage.scss';

const TITLE = 'Page Not Found';
const DESCRIPTION =
  "Unfortunately, the page you are looking for no longer exists. To continue discovering what's new and next in fashion, please use the links below.";

export const NotFoundPage: React.FC = () => {
  const { data, loading, error } = useQuery(CUSTOM_PAGE_QUERY, { variables: { slug: '404' } });

  useStatusCode(StatusCodes.NOT_FOUND);

  const topModules = useMemo(() => findSectionModules(data, 'top'), [data]);
  const headerModules = useMemo(() => findSectionModules(data, 'header'), [data]);
  const bottomModules = useMemo(() => findSectionModules(data, 'bottom'), [data]);

  if (loading) return <Loading />;
  if (error) throwQueryError(error);

  return (
    <>
      <PageMeta title={TITLE} description={DESCRIPTION} />

      <div className="NotFoundPage">
        <Constrain>
          <Stack space={2} className="NotFoundPage__head">
            <h1 className="NotFoundPage__title">{TITLE}</h1>
            <h2 className="NotFoundPage__description">{DESCRIPTION}</h2>
          </Stack>
          {topModules && <ModuleSection modules={topModules.modules} />}
          {headerModules && <ModuleSection modules={headerModules.modules} />}
          {bottomModules && (
            <EnableLazyLoad>
              <LazyLoad>
                <ModuleSection modules={bottomModules.modules} />
              </LazyLoad>
            </EnableLazyLoad>
          )}
        </Constrain>
      </div>
    </>
  );
};
