import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EmailVerificationStatus } from '../../constants';
import { useEmailVerificationStatusModal } from './useEmailVerificationStatusModal';

export const useShowEmailVerificationStatus = () => {
  const { status } = useParams<{ status: EmailVerificationStatus }>();
  const { showEmailVerificationStatusModal } = useEmailVerificationStatusModal();

  useEffect(() => {
    if (status) showEmailVerificationStatusModal({ status });
  }, [status, showEmailVerificationStatusModal]);
};
